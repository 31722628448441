<template>
  <div class="floor-plans">
    <div class="container my-5">
      <div class="text">
        <h2>Floor Plans</h2>
        <p>
          {{ $t("ServicesDesc") }}
        </p>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <figure>
            <img
              class="img-thumbnail"
              src="../assets/plans/ground-floor-plan.jpeg"
              alt="floor"
            />
          </figure>
        </div>
        <div class="col-lg-4">
          <figure>
            <img
              class="img-thumbnail"
              src="../assets/plans/first-floor-plan.jpeg"
              alt="floor"
            />
          </figure>
        </div>
        <div class="col-lg-4">
          <figure>
            <img
              class="img-thumbnail"
              src="../assets/plans/pond-floor-plan.jpeg"
              alt="floor"
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>