<template>
  <main class="main">
    <!-- main section -->
    <Suites />

    <section class="main-section mt-2">
      <div class="main-content container-fluid">
        <div class="row">
          <div class="main-left">
            <h2>{{ $t("MainTitle") }}</h2>
            <p>{{ $t("MainDesc") }}</p>
            <div class="mt-1">
              <router-link tag="a" to="/contact" :title="$t('Contact2')">{{
                $t("Contact2")
              }}</router-link>
              <a
                class="detail-btn"
                href="../docs/First-Class.pdf"
                target="_blank"
                :title="$t('PresentationFile')"
                >{{ $t("PresentationFile") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end main section -->
    <!-- main content bottom -->
    <section class="mt-5 main-bottom">
      <div class="text-center top mb-4">
        <p>{{ $t("MainBottomTitle") }}</p>
      </div>
      <div class="container d-flex justify-content-center">
        <div class="col-lg-3 text-center">
          <figure>
            <img src="../assets/icons/city.png" alt="city center" />
            <p>{{ $t("KadriyeCenter") }}</p>
          </figure>
        </div>
        <div class="col-lg-3 text-center">
          <figure>
            <img src="../assets/icons/plane.png" alt="airport" />
            <p>{{ $t("Airport") }}</p>
          </figure>
        </div>
        <div class="col-lg-3 text-center">
          <figure>
            <img src="../assets/icons/beach.png" alt="Beach" />
            <p>{{ $t("Beach") }}</p>
          </figure>
        </div>
      </div>
    </section>

    <!-- project details -->
    <section id="project-details" class="container mt-5 home-project-detail">
      <div class="text-left">
        <h2>{{ $t("ProjectDetails") }}</h2>
        <p>
          {{ $t("HomeProjectDesc") }}
        </p>
      </div>
      <div>
        <div class="row">
          <div class="col-lg-6 left">
            <figure>
              <img
                class="img-thumbnail"
                src="../assets/outside/first-class-1-big.jpg"
                alt="project detail"
              />
            </figure>
          </div>
          <div class="col-lg-6 right">
            <div class="row">
              <div class="col-lg-6">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="../assets/outside/first-class-8.jpg"
                    alt="project detail"
                  />
                </figure>
              </div>
              <div class="col-lg-6">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="../assets/outside/first-class-2.jpg"
                    alt="project detail"
                  />
                </figure>
              </div>
            </div>
            <div class="col">
              <p>{{ $t("ProjectShortDesc") }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- project details -->

    <!-- rooms tab start -->
    <section id="rooms" class="tab-side container-fluid py-5">
      <div class="container">
        <h2>{{ $t("Rooms") }}</h2>
        <p>{{ $t("RoomsDesc") }}</p>
      </div>
      <div class="container-fluid tabs-bg">
        <div class="container">
          <b-tabs content-class="mt-1">
            <!-- outside tab -->
            <b-tab>
              <!-- slider -->
              <VueperSlides
                :bullets="false"
                autoplay
                :gap="5"
                :lazy="true"
                :slideMultiple="true"
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in gallerySlider"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>
    <!-- rooms tabs end -->

    <!-- plans start -->
    <Plans />
    <!-- plans end -->

    <!-- our services start -->
    <section id="our-services" class="container services-section my-5">
      <div class="row">
        <div class="col-lg-4">
          <div class="card-item">
            <h3>{{ $t("CardItem1Title") }}</h3>
            <p>
              {{ $t("CardItem1Desc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-item">
            <h3>{{ $t("CardItem2Title") }}</h3>
            <p>
              {{ $t("CardItem2Desc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-item">
            <h3>{{ $t("CardItem3Title") }}</h3>
            <p>
              {{ $t("CardItem3Desc") }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- our services end -->
  </main>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Suites from "../components/Antalya-Suites-Comp.vue";
import Plans from "../components/Floor-Plans-Comp.vue";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    Suites,
    Plans,
  },
  data() {
    return {
      roomsVisibleSlides: null,
      testimonialsVisibleSlides: 3,
      gallerySlider: [
        {
          image: require("../assets/gallery/first-class-gallery-1.jpg"),
        },
        {
          image: require("../assets/gallery/first-class-gallery-2.jpg"),
        },
        {
          image: require("../assets/gallery/first-class-gallery-3.jpg"),
        },
        {
          image: require("../assets/gallery/first-class-gallery-4.jpg"),
        },
        {
          image: require("../assets/gallery/first-class-gallery-5.jpg"),
        },
        {
          image: require("../assets/gallery/first-class-gallery-6.jpg"),
        },
        {
          image: require("../assets/gallery/first-class-gallery-7.jpg"),
        },
        {
          image: require("../assets/gallery/first-class-gallery-8.jpg"),
        },
        {
          image: require("../assets/gallery/first-class-gallery-9.jpg"),
        },
      ],
    };
  },
  created() {
    if (window.innerWidth < 997) {
      this.roomsVisibleSlides = 1;
    } else {
      this.roomsVisibleSlides = 3;
    }
  },
};
</script>
