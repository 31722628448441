<template>
  <section>
    <Suites />
    <div class="gallery-page my-5">
      <div class="gallery">
        <VueGallery
          :images="images"
          :index="index"
          @close="index = null"
        ></VueGallery>
        <div
          class="image"
          v-for="(image, imageIndex) in images"
          :key="imageIndex"
          @click="index = imageIndex"
          :style="{
            backgroundImage: 'url(' + image + ')',
            width: '300px',
            height: '200px',
          }"
        ></div>
      </div>
    </div>
  </section>
</template>
<script>
import VueGallery from "vue-gallery";
import Suites from "../components/Antalya-Suites-Comp.vue";

export default {
  data: function () {
    return {
      images: [
        require("../assets/gallery/first-class-gallery-1.jpg"),
        require("../assets/gallery/first-class-gallery-2.jpg"),
        require("../assets/gallery/first-class-gallery-3.jpg"),
        require("../assets/gallery/first-class-gallery-4.jpg"),
        require("../assets/gallery/first-class-gallery-5.jpg"),
        require("../assets/gallery/first-class-gallery-6.jpg"),
        require("../assets/gallery/first-class-gallery-7.jpg"),
        require("../assets/gallery/first-class-gallery-8.jpg"),
        require("../assets/gallery/first-class-gallery-9.jpg"),
        require("../assets/gallery/first-class-gallery-11.jpg"),
        require("../assets/gallery/first-class-gallery-12.jpg"),
        require("../assets/gallery/first-class-gallery-13.jpg"),
        require("../assets/gallery/first-class-gallery-10.jpg"),
        require("../assets/gallery/first-class-gallery-14.jpg"),
        require("../assets/gallery/first-class-gallery-15.jpg"),
      ],
      index: null,
    };
  },

  components: {
    VueGallery,
    Suites,
  },
};
</script>
<style>
.gallery-page .gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.gallery-page .image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
  cursor: pointer;
}
</style>
