<template>
  <section>
    <Suites />
    <div class="project-section container my-5">
      <div>
        <h2>{{ $t("ProjectDetails") }}</h2>
        <p>
          {{ $t("ProjectDetailsDesc") }}
        </p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <figure>
            <img
              class="img-thumbnail"
              src="../assets/outside/first-class-2.jpg"
              alt=""
            />
          </figure>
        </div>
        <div class="col-lg-6 list">
          <ul>
            <li>
              <p>{{ $t("ProjectTopDesc1") }}</p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc2") }}</p>
            </li>
            <li>
              <p>
                {{ $t("ProjectTopDesc3") }}
              </p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc4") }}</p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc5") }}</p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc6") }}</p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc7") }}</p>
            </li>
          </ul>
        </div>
      </div>
      <!-- plans -->
      <Plans />
      <!-- detail boxes -->
      <div class="detail-boxes">
        <div class="box">
          <p>
            {{ $t("DetailBox1") }}
          </p>
        </div>
        <div class="box">
          <p>{{ $t("DetailBox2") }}</p>
        </div>
        <div class="box">
          <p>
            {{ $t("DetailBox3") }}
          </p>
        </div>
        <div class="box">
          <p>{{ $t("DetailBox4") }}</p>
        </div>
        <div class="box">
          <p>{{ $t("DetailBox5") }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Suites from "../components/Antalya-Suites-Comp.vue";
import Plans from "../components/Floor-Plans-Comp.vue";
export default {
  name: "Project-Page",
  components: {
    Suites,
    Plans,
  },
};
</script>

<style>
</style>